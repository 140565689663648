import React from 'react';
import { MainLayout } from '../layouts';
import {
  LegalContainer
} from '../components/pages/shared/LegalStyles';

export default () => (
  <MainLayout>
    <LegalContainer>
        <div>
        <h1 id="cookiepolicy">Cookie Policy</h1>
        <p><em>Effective Date: November 24, 2019</em></p>
        <p>This Cookie Policy describes how AirPage uses cookies and similar technologies to provide, customize, evaluate, improve, promote and protect our Services. Note that any capitalized terms not defined in this Cookie Policy have the meanings set forth in our <a href="/terms-of-service">Terms of Service</a>. If you have any comments or questions about this Cookie Policy, feel free to contact us at <a href="mailto:privacy@airpage.com">privacy@airpage.com</a>.</p>
        <p><strong>Cookies</strong>. Cookies are small pieces of text sent to your browser when you visit a site. They serve a variety of functions, like enabling us to remember certain information you provide to us as you navigate between pages on the Services. We use cookies on the website and associated domains of www.airpage.com and on AirPage web and mobile applications for the following purposes:</p>
        <ul>
            <li><p><strong>Authentication, Customization, Security and Other Functional Cookies</strong>. Cookies help us verify your Account and device and determine when you’re logged in, so we can make it easier for you to access the Services and provide the appropriate experiences and features. We also use cookies to help prevent fraudulent use of login credentials and to remember choices you’ve made on the Services, such as your language preference.</p></li>
            <li><p><strong>Performance And Analytics.</strong> Cookies help us analyze how the Services are being accessed and used, and enable us to track performance of the Services. For example, we use cookies to determine if you viewed a page or opened an email. This helps us provide you with information that you find interesting.</p></li>
            <li><p><strong>Third Parties.</strong> Third Party Services may use cookies to help you sign into their services from our Services. Any such third party cookie usage is governed by the policy of the third party placing the cookie.</p></li>
            <li><p><strong>AirPage Ads.</strong> We partner with third party publishers, advertising networks and service providers to manage our ads on other sites. Our third party partners may set cookies on your device or browser to gather information about your activities on the Services and other sites you visit, in order to provide you with AirPage ads. For example, if you visit AirPage and also use a social media platform, you may see a AirPage ad in your social media newsfeed or timeline.</p></li>
            <li><p><strong>Opting Out.</strong> You can set your browser to not accept cookies, but this may limit your ability to use the Services. We currently don’t respond to DNT:1 signals from browsers visiting our Services. You can also opt out of receiving interest-based ads from certain ad networks <a href="https://www.networkadvertising.org/choices/">here</a> (or if located in the European Union, <a href="https://www.youronlinechoices.eu/">here</a>).</p></li>
        </ul>
        <p><strong>Device Identifiers.</strong> We use device identifiers on AirPage web and mobile applications to track, analyze and improve the performance of the Services and our ads.</p>
        <p><strong>Third Party Tags.</strong> We use and manage third party tags on the website and associated domains of www.airpage.com and on AirPage web and mobile applications. Third party tags may take the form of pixels or tracking snippets. We use pixels to learn how you interact with our site pages and emails, and this information helps us and our ad partners provide you with a more tailored experience. We use tracking snippets to capture data from your browser, make requests to a third party partner or set cookies on your device to store data. For example, if you see a AirPage ad on a social media platform and choose to use AirPage, we may use a tag to reduce the number of AirPage ads you see on that platform. We use <a href="https://www.google.com/analytics/tag-manager/use-policy/">Google Tag Manager</a> to manage our third party tag usage. This may cause other tags to be activated which may, for their part, collect data and set cookies under certain circumstances. Google Tag Manager does not store this data.</p>
        <p><strong>Cookies on Your Sites.</strong> For information about the cookies and similar technologies used on Your Sites click <a href="https://support.airpage.com/hc/articles/360001264507">here</a>.</p>
        <p>We may update this Cookie Policy from time to time. When we make changes, we’ll update the “Effective Date” at the top of the Cookie Policy and post it on our sites. We encourage you to check back periodically to review this Cookie Policy for any changes since your last visit.</p>
        </div>
    </LegalContainer>
  </MainLayout>
);
